@import './variables.scss';

body {
  font-family: $font-body;
  font-size: 18px;
}

h6 {
  margin: 0;
}

h5 {
  font-weight: normal;
  font-size: 1rem;
}

h4 {
  font-weight: 500;
}

p {
  text-align: left;
  padding: 1rem;
  line-height: 1.5;
}

a {
  color: $srx-logo-blue-1;
  text-decoration: none;
  
  &:hover {
    color: $text-white;
    cursor: pointer;
  }
}

header {
  position: sticky;
  top: 0;
  z-index: 5;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 112px 1fr;  height: 100%;
}

nav { 
  background: $nav-background;
  width: 100%;
  
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 1.2rem 0;
    
    li {
      display: inline;
      padding: 0 1rem;
      font-size: 1.3rem;
      font-family: $font-body;
    }
  }
}

footer {
  background-color: $footer-background;
  color: $text-white;
  text-align: left;
  position: sticky;
  bottom: 0;
  height: 100%;
  vertical-align: center;

  h6 {
    padding: .5rem 1rem;
  }
}

.App {
  background-color: $app-background;
  color: $text-dark;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 2rem;
}

// NavBar 
.selected {
  color: $text-white;
  font-weight: 800;
}

// Buttons
button {
  background-color: $srx-logo-blue-3;
  border: none;
  border-radius: .2rem;
  color: $text-white;
  padding: 1rem;
  // margin: 2rem;
  font-family: $font-title;
  font-size: 1rem;

  &:hover {
    background-color: $srx-logo-blue-2;
    cursor: pointer;
  }
}

.button-container {
  text-align: center;
}

.big-button {
  height: 5rem;
  min-width: 20rem;
  font-size: 1.3rem;
}

.home-learn-more {
  font-size: 1.5rem;
  margin: 2rem 0; 
  box-shadow: $overlay-box-shadow;
  
  &:hover {
    background-color: $srx-logo-blue-2;
    color: $white;
  }
}

.view-profile {
  margin: 2rem 0;
  padding: 1rem;
  width: 80%;
}

// Home
.overlay-text {
  width: 65%;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1.5rem;
  font-family: $font-title;

  p {
    background: $overlay-background;
    box-shadow: $overlay-box-shadow;
    border-radius: .2rem; 
    padding: 4rem 8rem;
    line-height: 1.7;
  }
}

.div-list {
  text-align: left;
}

.list-text {
  background: $overlay-background;
  box-shadow: $overlay-box-shadow;
  border-radius: .2rem; 
  padding: 4rem;
  width: 65%;
  margin: 2.5rem auto;
  color: $srx-logo-blue-4;
  font-size: 1.5rem;

  h4 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
  }

  ol {
    width: 70%;
    margin: 0 auto;

    li {
      font-size: 2rem;
      padding: 2rem 0;
    }
  }

}

.div-no-image {
  background-color: $white;

  .overlay-text {
    width: 100%;
    margin: 0 auto;
    font-size: 1.5rem;
    color: $srx-logo-blue-4;
    font-family: $font-body;
  }

  .overlay-text-light {
    font-size: 1.5rem;
    color: $srx-site-blue-2;

    .text-strong {
      color: $text-white;
    }
  }

  p {
    background: none;
    padding: 3rem 6rem;
    box-shadow: none;
  }
}



// ABOUT
.about-page {
  p {
    margin: 0;
    font-weight: normal;
  }

  .overlay-text {
    margin: 4rem auto;
    font-weight: bold;
    color: $srx-site-blue-5;
  }

  .div-no-image {
    h3 {
      margin: 0;
      padding: 4rem 0;
      color: $srx-site-blue-2;
      font-size: 2.5rem;
      background: $srx-logo-blue-3;
    }

    p {
      color: $srx-logo-blue-1;
      background: $srx-site-blue-5-alpha;
      font-size: 1.4rem;
      padding: 4rem 12rem;
      line-height: 1.6;
    }

    .white {
      color: white;
    }
  }

  .div-graphic {
    border-radius: .2rem; 
   
    padding: 2rem;
    width: 75%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 2rem;

    p {
      margin: auto 0;
      padding: 2rem;
      color: $srx-logo-blue-4;
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 1.7;
    }

    img {
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
  }

  .products-link {
    margin-top: 4rem;
    background: $srx-logo-blue-2;
    font-family: $font-body;
    font-size: 1.5rem;

    &:hover {
      background: $white;
      color: $srx-logo-blue-2;
    }
  }
}

// PROFILES
.profile-preview-image {
  border-radius: .2rem;
  box-shadow: $overlay-box-shadow;
  margin-top: 1rem;
}

.profile-preview-text {
  margin: 0 1.7rem;
  padding-bottom: .25rem;
  text-align: left;

  button {
    width: 100%;
    margin: 1rem 0;
  }
}

.profile-preview-background {
  background: $overlay-background;
  border-radius: .2rem;
  box-shadow: $overlay-box-shadow;
}

.profile-srx-titles {
  font-size: 1rem;
  color: $srx-logo-blue-4;

  .company {
    font-weight: 700;
  }
}

.profile-outside-titles {
  font-size: 1rem;
  color: $text-medium;
  
  h4 {
    margin: .75rem 0;
  }

  .company {
    font-weight: 700;
  }
}

.profile-name {
  font-size: 1.8rem;
  color: $srx-logo-blue-4;
}

.profile-background {
  display: grid;
  grid-template-columns: 1fr 25%;
  grid-template-rows: 1fr 1fr;
  column-gap: 2rem;
  margin-top: 1rem;
  padding: 2rem;
}

.profile-text-section {
  grid-area: 1 / 1 / 3 / 2; 
  text-align: left;
  background: $overlay-background;
  border-radius: .2rem;
  box-shadow: $overlay-box-shadow;
  padding: 2rem;

  p {
    padding: 0;
  }
}

.profile-title-section {
  h2 {
    margin: 0;
    padding-bottom: .5rem;
  }

  h4 {
    margin: 0;
    color: $srx-logo-blue-4;
  }
}

.profile-image-section {
  grid-area: 1 / 2 / 2 / 3;
  min-width: 100%;

  img {
    border-radius: .2rem;
    max-width: 300px;
    border-radius: 2px;
    box-shadow: $overlay-box-shadow;
  }
}

.return-button {
  grid-area: 2 / 2 / 3 / 3;
  max-width: 300px;
  margin: 1rem auto;
  padding: 1rem 4rem;
  box-shadow: $overlay-box-shadow;
}

// Product Previews
.products-page {
  margin: 0 auto;

  .div-no-image {
    background: $overlay-background-light;
  }

  .overlay-text {
    margin: 0;
    padding: 0;
    
    h3 {
      font-size: 2.5rem;
      margin: 0;
      padding: 3rem 0;
      color: $srx-site-blue-2;
      background: $srx-logo-blue-3;
    }
    
    p {
      width: 75%;
      margin: 0 auto;
      padding: 3rem 0;
    }
  }

  .desktop-wrapper {
    width: 90%;
    padding: 1rem;
    margin: 0 auto;
  }

  .product-index {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    justify-content: center;
    margin: 0 auto;

    .product-preview:nth-child(1) {
      display: flex;
      grid-area: 1 / 1 / 2 / 4;
      margin: 0 auto;

      p {
        padding: .5rem 1rem;
      }
    }
    
    .product-preview:not(:first-child) {
      padding: 2rem 1rem;
      margin: 0 auto;
    }

    img {
      padding: 1rem;
    }

    p {
      padding: 0;
      margin: 0 auto;
    }

    .preview-text {
      font-size: 1.2rem;
      padding: 0 1.5rem 2rem;
    }

    .headline {
      font-family: $font-title;
      font-weight: bold;
      color: $srx-logo-blue-4;
      padding: 1rem 0 .5rem;
      font-size: 2rem;
    }

    .link {
      text-align: left;
      margin: 1rem 0;

      a {
        color: $srx-logo-blue-4;
        text-decoration: underline;
        font-family: $font-title;
        font-weight: bold;
        font-size: 1rem;

        &:hover {
          color: $srx-logo-blue-3;
        }
      }
    }
  }

  .product-preview {
    border-radius: .2rem;
    background: $overlay-background;
    box-shadow: $overlay-box-shadow;
    margin: 1rem;
    font-size: 1.4rem;
    padding: 2rem;
  }

  .white {
    color: $white;
  }
}



// MigrnX Product Page
.migrnx-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, auto);
  row-gap: 2rem;
  column-gap: 2rem;
  padding: 1rem 0;
  background: $migrnx-logo-blue-1;

  h4 {
    text-align: left;
    padding: 0 1rem;
    font-weight: 800;
  }

  .migrnx-r-1-c-1 {
    grid-area: 1 / 1 / 1 / 2;
    object-fit: contain;
    margin-left: 10rem;
    justify-self: right;
  }
  
  .migrnx-r-1-c-2-3 {
    grid-area: 1 / 2 / 2 / 4;
    margin-right: 10rem;
  }

  .migrnx-r-2-c-1-2-3 {
    grid-area: 2 / 1 / 3 / 4;
    justify-self: center;
  }

  .migrnx-r-3-c-1-2-3 {
    grid-area: 3 / 1 / 4 / 4;
    justify-self: center;
    margin: 0 10rem;
    width: stretch;
    
  }

  .migrnx-r-4-c-1-2 {
    grid-area: 4 / 1 / 5 / 3;
    margin-left: 10rem;
  }

  .migrnx-r-4-c-3 {
    grid-area: 4 / 3 / 5 / 4;
    margin-right: 10rem;
  }

  .migrnx-r-5-c-1-2-3 {
    grid-area: 5 / 1 / 6 / 4;
    justify-self: center;
    width: stretch;
    margin: 0 10rem;
  }

  .migrnx-r-6-c-1-2-3 {
    grid-area: 6 / 1 / 7 / 4;
    margin: 0 10rem;
  }
}

.horizontal-text-bar {
  background: $migrnx-logo-blue-3;
  color: $migrnx-logo-green-1;
  width: 100%;
  padding: 3rem;
  font-family: $font-title;
}

// NEWS
.header-text {
  h3 {
    font-size: 2.5rem;
    margin: 0;
    padding: 3rem 0;
    color: $srx-logo-blue-1;
    font-weight: bold;
    background-color: $srx-logo-blue-3;
  }
  
  .white {
    color: $white;
  }
}

.news-section {
  margin: 2rem auto;
  text-align: left;
  width: 50%;

  .news-item {
    background: $overlay-background-light;
    box-shadow: $overlay-box-shadow;
    border-radius: .2rem;
    padding: 2rem 3rem;
    margin: 1rem auto;
    
    &:hover {
      position: relative;
      top: 1px;
      left: 1px;
    }

    a {
      color: $text-dark;
    }

    h3 {
      margin: 0;
      font-size: 1.3rem;
      font-weight: normal;
      padding-bottom: .5rem;
      text-decoration: underline;
    }

    h5 {
      margin: 0;
      color: $srx-logo-blue-4;
      font-weight: bold;
    }

    h6 {
      font-weight: normal;
    }
  }


}

// CONTACT
.contact-grid {
  background: $overlay-background;
  border-radius: .2rem;
  box-shadow: $overlay-box-shadow;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  column-gap: 1rem;
  row-gap: 1rem;
  min-width: 30rem;
  width: 30%;
  margin: 0 auto;
  padding: 2rem;

  h5 {
    margin: .5rem;
    font-size: 1.5rem;
  }

  .row-1-col-1 {
    grid-area: 1 / 1 / 2 / 2;
    justify-self: end;

    img {
      object-fit: contain;
    }
  }

  .row-1-col-2 {
    grid-area: 1 / 2 / 2 / 3;
    justify-self: start;
    text-align: left;
  }

  .row-2-col-1 {
    grid-area: 2 / 1 / 3 / 2;
    justify-self: end;
  }

  .row-2-col-2 {
    grid-area: 2 / 2 / 3 / 3;
    justify-self: start;
  }

  .row-3-col-1 {
    grid-area: 3 / 1 / 4 / 2;
    justify-self: end;
  }

  .row-3-col-2 {
    grid-area: 3 / 2 / 4 / 3;
    justify-self: start;
  }
}




// Images
.rounded {
  border-radius: .2rem;
}

// Text Elements
.text-strong {
  color: $srx-logo-blue-4;
  font-weight: 700;
}

.left-align {
  text-align: left;
}


///// DESKTOP VIEW SETTINGS /////
// HEADER
.header-logo {
  height: 4rem;
  padding: 1.5rem;
  margin: 0 auto;
}

.header-logo-area {
  background: $header-background;
  background: $header-radial;
}

// Wrapper to center unique page components (wraps all EXCEPT Header & Footer)
.page-wrapper {
  width: 80vw;
  margin: 0 auto;
  padding: 1rem 0;
}

.about-page {
  img {
    max-width: 100%;
    padding: 4rem;
    background: none;
    margin: 1rem 2rem;
  }

  .block-bg {
    color: $srx-site-blue-1;
    background: $srx-site-blue-4;
    margin: 0;
    padding: 3rem 1rem;

    .text-strong {
      color: $srx-site-blue-2;
    }

    p {
      margin: 0 auto;
      width: 90%;
    }
  }
  .header-block {
    margin: 0 auto;
  }
}

// Wrapper for Profile Previews (confines to 1/3 page blocks)
.profile-preview {
  width: 350px;
  padding: 2rem;
  margin: 1rem; 
}

// Wrapper for Profile Index views
.profile-index {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem auto;
}



///// MOBILE VIEW SETTINGS /////


.mobile-wrapper {
  margin: 0 auto;
}

.mobile {

   
  .page-wrapper {
    margin: 0 auto;
    padding: 0;
  }

  main {
    margin: 0 auto;
    padding: 0;
  }

  // BUTTONS
  button {
    font-size: 1rem;
    padding: 1.5rem 2rem;
    margin: 1rem auto;
    display: block;
    width: 100%;
  }
  
  // HEADER
  header {
    display: block;
  }

  .header-text {
    h3 {
      display: block;
      width: 100vw;
      font-size: 1.5rem;
      margin: 0;
      padding: 2rem 1rem;
      color: $srx-logo-blue-1;
      font-weight: bold;
      background-color: $srx-logo-blue-3;
    }
  }
  
  
  .header-logo {
    height: 2rem;
    padding: 1.5rem;
    margin: 0 auto;
  }

  // NAV
  nav {
    text-align: left;
    background: $nav-background-mobile;
    font-size: 1.2rem;
  }
  
  // Burger Menu
  .bm-menu {
    width: 15rem;
  }

  .bm-item-list {
    padding-top: 1.5rem;
    box-sizing: border-box;
  }

  .bm-item {
    padding: .7rem 2rem;
  }

  .bm-burger-button {
    position: fixed;
    width: 38px;
    height: 35px;
    margin: 0;
    top: 25px;
    left: 25px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $srx-site-blue-5;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: $srx-site-blue-1;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 30px;
    width: 30px;
    position: fixed;
    left: 208px;
    top: 38px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: $srx-site-blue-1;
  }

  .home-page {
    button {
      margin-bottom: 1rem;
    }
    .list-text{
      background: $srx-site-blue-5;
      box-shadow: none;
      width: 100%;
      margin: 0;
      padding: 1rem 0 0;

      ol {
        font-size: 1rem;
      }

      h4 {
        font-size: 1.5rem;
        width: 80%;
        margin: 0 auto;
        color: $srx-logo-blue-2;
      }

      li {
        font-size: 1.2rem;
        color: $white;
      }
    }
    .div-no-image {
      padding: 1rem;
    }
    .overlay-text {
      margin: 0 auto;
      padding: 0;
      width: 90%;

      p {
        background-color: none;
        padding: 1rem;
        font-size: 1.1rem;
      }

      .mobile-button {
        background: $srx-logo-blue-3;
        width: 90%;
        font-size: 1.25rem;
        padding: 1.5rem;
      }
    }
  }

  // ABOUT
  .about-page {
    max-width: 100vw;
    background: $srx-site-blue-1;

    h3 {
      font-size: 1.5rem;
      padding: 3rem 1rem;
    }

    .div-graphic {
      display: block;
      padding: 0;
      

      p {
        padding: 2rem 0;
        font-size: 1rem;
      }
      img {
        display: none;
      }
    }

    .div-no-image {
      h3 {
        padding: 2rem 1rem;
        margin: 0;
      }

      p {
        padding: 2rem 3rem;
        font-size: 1rem;
      }

      a {
        width: 100%;
      }

      .big-button {
        min-width: 100%;
        font-weight: bold;
        font-size: 2rem;
      }

      .products-link  {
        font-size: 1rem;
        width: 50%;
        margin: 2rem auto;
      }
    }

    .overlay-text {
      margin: 0 auto;
      padding: 0;
      //

      p {
        background-color: none;
        padding: 1rem;
        font-size: 1.1rem;
      }
    }
  }

  // PROFILES
  .profile-index {
    display: block;
    padding: 0;
    margin: 0 auto;
    
    .profile-preview {
      margin: 1rem auto;
      max-width: 180px;

      .profile-preview-image {
        width: 100%;
        margin: 0;
      }

      .profile-preview-text {
        margin: 0 auto;
      }

      .profile-name {
        font-size: 1.6rem;
      }

      button {
        margin: 2rem 0 1.5rem;
        width: 100%;
      }

    }
  }

  .profile-background {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    background: $overlay-background;
    border-radius: .2rem;
    box-shadow: $overlay-box-shadow;
    padding: 2rem;

    .profile-image-section {
      grid-area: 1 / 1 / 2 / 2;
      
      .profile-image {
        max-width: 100%;
      }
    }

    .profile-text-section {
      grid-area: 2 / 1 / 3 / 2;
      background: none;
      box-shadow: none;
      padding: 0;
      
      h4 {
        padding: .2rem 0;
        font-size: .9rem;
      }

      p {
        padding: 0;
      }
    }

    .profile-name {
      font-size: 1.6rem;
      padding: 1rem 0;
    }

    .profile-title-section {
      padding: .5rem 0;
    }

    .return-button .button {
      grid-area: 3 / 1 / 4 / 2;
      margin: 2rem auto;
      padding: 1rem 2rem;
    }
  }

  // NEWS
  .news-section {
    margin: 0 auto;
    padding: 0;
  }

  .news-item {
    padding: 1rem;

    h3 {
      font-size: 1.1rem;
    }
  }

  // PRODUCTS
  .products-page {
    h3 {
      font-size: 2rem;
      padding: 1.5rem 0;
    }

    p {
      font-size: 1.2rem;
      padding: 2rem;
      width: 80%;
      margin: 0 auto;
    }

    .preview-text {
      padding: 1rem 0 0;
    }
  }

  .product-index {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 300px;
    
    
    .product-preview:nth-child(1) {
      display: block;
      justify-self: center;
      padding: 1rem 0 2rem;
    }

    .product-preview:not(:first-child) {
      display: block;
      justify-self: center;
      padding: 1rem 0 2rem;
    }

    .product-logo-container {
      padding: 1rem 0 0;
    }

    .product-preview {
      p {
        font-size: 1rem;
        padding: 0;
      }
  
      button {
        margin: 1rem;
        padding: 1.5rem 1rem;
        font-size: 1rem;
        width: 90%;
      }

      .headline {
        font-family: $font-title;
        font-weight: bold;
        color: $srx-logo-blue-4;
        padding: 0 0 .5rem;
        font-size: 1.5rem;
      }
    }
  }

  

  // PARTNERS
  .partners {
    img {
      padding: 1rem;
      max-width: 100%;
    }
  }

  // CONTACT
  .contact-page {
    margin: 1rem;
    text-align: left;
    
    .mobile-wrapper {
      padding: 1rem 2rem;

      .contact-background {
        background: $overlay-background;
        border-radius: .2rem;
        box-shadow: $overlay-box-shadow;
        padding: 1rem;
        margin: 1rem auto;
        min-width: 15rem;

        h5 {
          margin: .5rem;
          font-size: 1.2rem;
          padding: 0 auto;
        }
      }
    }
  }
}