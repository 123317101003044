@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap');

///// VARIABLES /////
$text-dark: #32343a;
$text-medium: #505050;
$text-light: #96989e;
$text-white: #ffffff;
$white: #ffffff;

// Srx Logo Colors
$srx-logo-blue-1: #7CCFDA;
$srx-logo-blue-2: #30ACBD;
$srx-logo-blue-3: #2E7DC1;
$srx-logo-blue-4: #204894;

// Custom Srx Color Shades
$srx-site-blue-1: #ECFFFF;
$srx-site-blue-2: #B9E1FF;
$srx-site-blue-3: #9FC7FF;
$srx-site-blue-4: #537BC7;
$srx-site-blue-5: #001561;
$srx-site-blue-5-alpha: #001561E6;

// MigrnX Logo Colors
$migrnx-logo-blue-1: #c7e8f3;
$migrnx-logo-blue-2: #6c969d;
$migrnx-logo-blue-3: #114b5f;
$migrnx-logo-green-1: #88d498;
$migrnx-logo-green-2: #1a936f;

// Backgrounds
$header-background: $srx-site-blue-1;
$header-radial: radial-gradient(circle,  $srx-site-blue-1 0%, $srx-site-blue-3 50%, $srx-site-blue-4 100%);

$nav-background: $srx-logo-blue-4;
$nav-background-mobile: $srx-site-blue-5-alpha;

$app-background: $srx-site-blue-3;

$overlay-background: #ffffff99;
$overlay-background-light: #ffffff66;
$overlay-box-shadow-outset: 0 0 .5rem #00000020;
$overlay-box-shadow-inset: 0 0 .1rem #ffffff40 inset;
$overlay-box-shadow: $overlay-box-shadow-inset, $overlay-box-shadow-outset;


$footer-background: $srx-logo-blue-4;

// Fonts
$font-title: 'PT Sans', sans-serif;
$font-body: 'Open Sans', sans-serif;